<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <template v-if="detail !== null">
      <v-row>
        <v-col>
          <span
            style="cursor: pointer"
            @click="navBack"
          >
            <v-icon
              style="
              font-size: 16px;
              color: #7A1878;
            "
            >
              fas fa-chevron-left
            </v-icon>
            <span
              class="ml-1"
              style="color: #7A1878"
            >
              Kembali
            </span>
          </span>
        </v-col>
      </v-row>

      <v-row class="mt-n5">
        <v-col style="font-size: 26px">
          Detail Aset
        </v-col>
      </v-row>

      <v-row class="mt-n5">
        <v-col>
          <!-- Informasi Aset -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Informasi Aset
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common d-flex"
                cols="3"
                sm="3"
                style="flex-direction: column"
              >
                <span>
                  Nama Mitra
                </span>

                <span class="font-common-bold">
                  {{ detail.prt_name_legal }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="3"
                style="flex-direction: column"
              >
                <span>
                  Kode Aset
                </span>

                <span class="font-common-bold">
                  {{ detail.code }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="3"
                style="flex-direction: column"
              >
                <span>
                  Nama Media
                </span>

                <span class="font-common-bold">
                  {{ detail.name }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="3"
                style="flex-direction: column"
              >
                <span>
                  Status Media
                </span>

                <span class="font-common-bold">
                  {{ detail.status_string }}
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- Informasi Umum -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Informasi Umum
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common d-flex"
                cols="3"
                sm="2"
                style="flex-direction: column"
              >
                <span>
                  Kode Internal
                </span>

                <span class="font-common-bold">
                  {{ detail.internal_code }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="1"
                style="flex-direction: column"
              >
                <span>
                  Tipe
                </span>

                <span class="font-common-bold">
                  {{ detail.type }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="1"
                style="flex-direction: column"
              >
                <span>
                  Venue
                </span>

                <span class="font-common-bold">
                  {{ detail.venue }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="2"
                style="flex-direction: column"
              >
                <span>
                  Pencahayaan
                </span>

                <span class="font-common-bold">
                  {{ detail.light }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="2"
                style="flex-direction: column"
              >
                <span>
                  Potensi Lalu Lintas
                </span>

                <span>
                  <!-- Car Active: 1 -->
                  <template
                    v-if="detail.traffic_code === 1"
                  >
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                    <v-icon>
                      mdi-car
                    </v-icon>
                    <v-icon>
                      mdi-car
                    </v-icon>
                  </template>

                  <!-- Car Active: 2 -->
                  <template
                    v-if="detail.traffic_code === 2"
                  >
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                    <v-icon>
                      mdi-car
                    </v-icon>
                  </template>

                  <!-- Car Active: 3 -->
                  <template
                    v-if="detail.traffic_code === 3"
                  >
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                    <v-icon color="purple">
                      mdi-car
                    </v-icon>
                  </template>
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="1"
                style="flex-direction: column"
              >
                <span>
                  Pesanan
                </span>

                <span class="font-common-bold">
                  {{ detail.order_count }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="2"
                style="flex-direction: column"
              >
                <span>
                  Pesanan Offline
                </span>

                <span class="font-common-bold">
                  {{ detail.order_offline_count }}
                </span>
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="1"
                style="flex-direction: column"
              >
                <span>
                  Like
                </span>

                <span class="font-common-bold">
                  {{ detail.count_fav }}
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- Harga Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Harga Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="2"
                sm="2"
              >
                Biaya Cetak
              </v-col>

              <v-col
                class="font-common d-flex"
                cols="3"
                sm="3"
              >
                <!-- <span>
                  Rp.
                </span> -->
                <span class="font-common-bold">
                  {{ 'Rp. ' + (detail.price_print || 0) | dotThousandSeparator }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="2"
                sm="2"
              >
                Biaya Pasang
              </v-col>

              <v-col
                class="font-common-bold"
                cols="3"
                sm="3"
              >
                {{ 'Rp. ' + (detail.price_install || 0) | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="3"
                sm="3"
              />

              <v-col
                class="font-common"
                cols="2"
                sm="2"
              >
                Sewa Media
              </v-col>

              <v-col
                class="font-common"
                cols="2"
                sm="2"
              >
                Pajak Reklame
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Harga Tahunan
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.price_year || 0) | dotThousandSeparator }}
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.ads_tax_year || 0) | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Harga Bulanan
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.price_month || 0) | dotThousandSeparator }}
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.ads_tax_month || 0) | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Harga Harian
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.price_day || 0) | dotThousandSeparator }}
              </v-col>

              <v-col
                class="font-common-bold"
                cols="2"
                sm="2"
              >
                {{ 'Rp. ' + (detail.ads_tax_month || 0) | dotThousandSeparator }}
              </v-col>
            </v-row>
          </v-card>

          <!-- Lokasi Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Lokasi Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
                style="flex-direction: column"
              >
                <div>
                  <div class="font-common">
                    Alamat
                  </div>

                  <div class="font-common-bold">
                    {{ detail.ass_address_complete }}
                  </div>
                </div>

                <div class="mt-3">
                  <div class="font-common">
                    Tampak
                  </div>

                  <div class="font-common-bold">
                    {{ detail.view_point }}
                  </div>
                </div>

                <!-- <div class="mt-3 d-flex">
                  <span class="mr-2">
                    <label
                      class="font-common"
                      for="latitude"
                    >Latitude</label>
                    <v-text-field
                      id="latitude"
                      v-model="latitude"
                      label="Masukkan Latitude"
                      dense
                      outlined
                    />
                  </span>

                  <span class="ml-2">
                    <label
                      class="font-common"
                      for="longitude"
                    >Longitude</label>
                    <v-text-field
                      id="longitude"
                      v-model="longitude"
                      label="Masukkan Longitude"
                      dense
                      outlined
                    />
                  </span>
                </div> -->
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <GmapMap
                  :center="mapCoordinate"
                  :zoom="15"
                  map-type-id="roadmap"
                  style="width: auto; height: 300px;"
                  :options="mapOptions"
                >
                  <GmapMarker
                    :position="mapCoordinate"
                  />
                </GmapMap>
                <a
                  target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&query=' + mapCoordinate.lat + ',' + mapCoordinate.lng"
                >
                  Buka peta di Google Maps
                </a>
              </v-col>
            </v-row>
          </v-card>

          <!-- Foto Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Foto Media
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <template v-if="detail.photos.length > 0">
                  <tiny-slider
                    ref="tinySlider"
                    v-bind="tinySliderOptions"
                  >
                    <div
                      v-for="(photo, i) in detail.photos"
                      :key="i"
                    >
                      <v-img
                        :src="photo.link"
                        contain
                        width="300px"
                        style="cursor: pointer"
                        @click="downloadAttachment(photo)"
                      />
                    </div>
                  </tiny-slider>

                  <template v-if="detail.photos.length > 3">
                    <div
                      id="arrow-navigation"
                      class="d-flex justify-space-between arrow"
                    >
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="prev"
                      >
                        mdi-chevron-left
                      </v-icon>
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="next"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </div>

                    <div
                      id="dot-navigation"
                      class="d-flex justify-center py-3 mt-6"
                    >
                      <span
                        v-for="(n, i) in listImages"
                        :id="'dot-' + i"
                        :key="n"
                        class="dot"
                        @click="navigationClick(i)"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  Belum ada foto
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Dokumen Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Dokumen Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="(v, i) in detail.documents"
                :key="'document-' + i"
                class="d-flex"
                cols="12"
                sm="6"
                style="flex-direction: column"
              >
                <span class="font-common">
                  {{ v.name }}
                </span>

                <span class="font-common-bold mt-1">
                  {{ (v.file_name || '-') + ' (' + (v.file_size || 0) + ')' }}
                  <span
                    class="ml-1"
                    style="cursor: pointer"
                    @click="downloadAttachment(v)"
                  >
                    <v-icon>
                      fas fa-arrow-alt-circle-down
                    </v-icon>
                  </span>
                </span>

                <span
                  class="font-common mt-1"
                  style="font-size: 12px"
                >
                  Status
                  <span
                    class="font-common-bold"
                    :style="'color: #' + v.status_color"
                    style="font-size: 12px"
                  > {{ v.status_string }} </span>
                </span>

                <span
                  class="font-common mt-1"
                  style="font-size: 12px"
                >
                  Perpanjangan pada
                  <span
                    class="font-common-bold"
                    style="font-size: 12px"
                  > {{ v.expired_date_format || '-' }} </span>
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- Sisi Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Sisi Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="6"
                sm="6"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Sisi 1
                </span>

                <span>
                  null
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="6"
                sm="6"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Sisi 2
                </span>

                <span>
                  null
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- Riwayat Pesanan -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Riwayat Pesanan
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  :headers="headerPesanan"
                  :items="pesanan"
                  :items-per-page="25"
                  class="elevation-0"
                  mobile-breakpoint="100"
                  hide-default-footer
                >
                  <template v-slot:item.contract_validity_date="{ item }">
                    <span v-if="item.contract_validity_date !== null">
                      {{ item.contract_validity_date }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>

                  <template v-slot:item.share_percent="{ item }">
                    <span v-if="item.share_percent !== null">
                      {{ item.share_percent + '%' }}
                    </span>
                    <span v-else>
                      0%
                    </span>
                  </template>

                  <template v-slot:item.wallet_saldo="{ item }">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <span>
                        Rp
                      </span>

                      <span>
                        {{ item.wallet_saldo | dotThousandSeparator }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:footer>
                    <div class="text-center my-5 py-3">
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        color="purple"
                        @input="onChangePage"
                      />
                      <!-- <span style="font-size: 12px; font-style: italic;">
                        Page {{ page }} of {{ pageCount || 1 }}
                      </span> -->
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                      </span>
                    </div>
                    <!-- <v-row class="mt-n16">
                      <v-col class="text-right pr-8">
                        <span style="font-size: 12px; font-style: italic;">
                          {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                        </span>
                      </v-col>
                    </v-row> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>

          <!-- Riwayat Pesanan Offline -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Riwayat Pesanan Offline
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  :headers="headerPesananOffline"
                  :items="pesananOffline"
                  :items-per-page="25"
                  class="elevation-0"
                  mobile-breakpoint="100"
                  hide-default-footer
                >
                  <template v-slot:item.contract_validity_date="{ item }">
                    <span v-if="item.contract_validity_date !== null">
                      {{ item.contract_validity_date }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>

                  <template v-slot:item.share_percent="{ item }">
                    <span v-if="item.share_percent !== null">
                      {{ item.share_percent + '%' }}
                    </span>
                    <span v-else>
                      0%
                    </span>
                  </template>

                  <template v-slot:item.wallet_saldo="{ item }">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <span>
                        Rp
                      </span>

                      <span>
                        {{ item.wallet_saldo | dotThousandSeparator }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:footer>
                    <div class="text-center my-5 py-3">
                      <v-pagination
                        v-model="pagePesananOffline"
                        :length="pageCountPesananOffline"
                        color="purple"
                        @input="onChangePagePesananOffline"
                      />
                      <!-- <span style="font-size: 12px; font-style: italic;">
                        Page {{ pagePesananOffline }} of {{ pageCountPesananOffline || 1 }}
                      </span> -->
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountPesananOffline + ' dari ' + itemTotalPesananOffline + ' ditampilkan' }}
                      </span>
                    </div>
                    <!-- <v-row class="mt-n16">
                      <v-col class="text-right pr-8">
                        <span style="font-size: 12px; font-style: italic;">
                          {{ itemCountPesananOffline + ' dari ' + itemTotalPesananOffline + ' ditampilkan' }}
                        </span>
                      </v-col>
                    </v-row> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>

          <!-- Jadwal Pemeliharaan -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Jadwal Pemeliharaan
              </v-col>
            </v-row>
          </v-card>

          <!-- BLOKIR ASET -->
          <template>
            <v-row class="my-4">
              <v-col class="text-right">
                <v-btn
                  class="btn btn-blok-aset"
                  @click="dialogBlokirAset = true"
                >
                  <span class="text-capitalize font-common-white-bold">
                    <span v-if="detail.status == 6">
                      Unblokir Aset
                    </span>
                    <span v-else>
                      Blokir Aset
                    </span>
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </template>

    <!-- DIALOG BLOKIR ASET -->
    <v-dialog
      v-model="dialogBlokirAset"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin memblokir aset ini?
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col>
            <v-text-field
              id="password-blokir"
              v-model="password"
              label="Masukkan Password"
              color="purple"
              outlined
              dense
              auto-complete="off"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword = !showPassword)"
            />
          </v-col>
        </v-row> -->

        <v-row class="">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirAset"
            >
              <span class="font-common-white-bold">
                Ya, Lanjutkan
              </span>
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogBlokirAset = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG UN-BLOKIR MITRA -->
    <!-- <v-dialog
      v-model="dialogUnBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin buka blokir mitra ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              id="password-buka-blokir"
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(1)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogUnBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      tabDialog: null,
      dialog: false,

      // Aset:
      detail: null,

      // Section Location:
      latitude: '',
      longitude: '',
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      },
      coordinate: {
        lat: -6.995092,
        long: 110.420914,
      },

      // Photos Section:
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        items: 3,
        controls: false,
      },

      // Pesanan:
      headerPesanan: [
        { text: 'Kode Pesanan', align: 'start', value: 'name', sortable: false },
        { text: 'Tanggal Pesanan', value: 'owner_name' },
        { text: 'Nama Penyewa', value: 'akot_string', sortable: false },
        { text: 'Durasi', value: 'contract_validity_date' },
        { text: 'Nominal', value: 'share_percent' },
      ],
      pesanan: [],

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Pesanan Offline:
      headerPesananOffline: [
        { text: 'Nama Perusahaan', align: 'start', value: 'name', sortable: false },
        { text: 'Nama Penyewa', value: 'owner_name' },
        { text: 'Nomor Telepon', value: 'akot_string', sortable: false },
        { text: 'Tanggal Pesanan', value: 'contract_validity_date' },
        { text: 'Durasi', value: 'share_percent' },
      ],
      pesananOffline: [],

      pagePesananOffline: 1,
      pageCountPesananOffline: 0,
      itemCountPesananOffline: 0,
      itemTotalPesananOffline: 0,

      dialogBlokirAset: false,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      mapCoordinate () {
        const retVal = {
          lat: '',
          lng: '',
        }

        if (this.detail !== null) {
          if (this.detail.coordinate) {
            retVal.lat = this.detail.coordinate.lat
            retVal.lng = this.detail.coordinate.long
          } else {
            retVal.lat = this.coordinate.lat
            retVal.lng = this.coordinate.long
          }
        }

        return retVal
      },

      listImages () {
        let imageCount = 0
        if (this.detail.photos) {
          imageCount = this.detail.photos.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 3)
        }

        return imageCount
      },

      assetEdit () {
        let v = false
        if (this.userMenus.includes('ASSET_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getPesanan(1, this.itemsPerPage)
      this.getPesananOffline(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          ass_hash: this.$route.params.id,
        }

        axios.post('/v2/t/asset/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
            console.log(vm.detail)
          }
        }).catch((e) => {
          if (typeof (e.response.data.error) === 'object') {
            this.$toast.error(Object.values(e.response.data.error)[0])
          } else {
            this.$toast.error(e.response.data.error)
          }
        })
      },

      getPesanan (page, limit) {
        const vm = this

        const requestBody = {
          ass_hash: this.$route.params.id,
          page: page,
          limit: limit,
        }

        axios.post('/v2/t/asset/detail/order', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.pesanan = res.data.data.detail
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getPesananOffline (page, limit) {
        const vm = this

        const requestBody = {
          ass_hash: this.$route.params.id,
          page: page,
          limit: limit,
        }

        axios.post('/v2/t/asset/detail/order-offline', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.pesananOffline = res.data.data.detail
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      blokirAset () {
        this.dialogBlokirAset = false

        const requestBody = {
          asset_id: this.$route.params.id,
        }

        axios.post('/v2/t/asset/mod/block', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.message)
            setTimeout(function () {
              window.location.reload(1)
            }, 1500)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadAttachment (data) {
        // console.log(data)
        let type = ''

        if (data.file_type) {
          type = data.file_type.toUpperCase()
        } else {
          type = 'IMAGE'
        }

        if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
          const modal = document.getElementById('myModal')
          const modalImg = document.getElementById('img01')
          modal.style.display = 'block'
          if (data.link) modalImg.src = data.link
          if (data.url) modalImg.src = data.url
          if (data.path) modalImg.src = data.path
          if (data.file_path_view_only) modalImg.src = data.file_path_view_only
          const span = document.getElementsByClassName('close')[0]
          span.onclick = function () {
            modal.style.display = 'none'
          }
        } else if (type === 'PDF') {
          window.open(data.url, '_blank')
        } else {
          window.open(data.url_download, '_blank')
        }
      },

      prev () {
        this.$refs.tinySlider.slider.goTo('prev')
      },

      next () {
        this.$refs.tinySlider.slider.goTo('next')
      },

      navigationClick (i) {
        this.$refs.tinySlider.slider.goTo(i)
      },

      toDetail (item) {
        console.log(item)
        this.$router.push({ name: 'AsetDetail', params: { id: item.hash } })
      },

      navBack () {
        this.$router.push({ name: 'Aset' })
      },

      onChangePage (e) {
        this.getPesanan(e, this.itemsPerPage)
      },

      onChangePagePesananOffline (e) {
        this.getPesananOffline(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}

#listImages {
  cursor: pointer;
}

.tns-item {
  padding: 5px;
  height: 250px;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  padding-top: 10px;
}

.dot-active, .dot:hover {
  background-color: #717171;
}

/* Next & previous buttons */
.arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: white;
  width: 96%;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
